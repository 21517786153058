<template>
  <div class="table-check-container">
    <div class="total-price-checks pt-1 px-1 font-weight-bold d-flex flex-wrap align-items-center justify-content-between">
      <h4>Выбор фото идентификации</h4>
      <div style="font-size:14px">
        <span>Сумма услуг:</span>
        <span style="height: 24px; margin-left: 9px;"> {{ calcTotalPrice }} руб</span>
      </div>
    </div>
    <div class="px-1">Для быстрой приемки товары выберите фото наиболее точно отображающие ваш товар</div>
    <div class="p-2 d-flex flex-wrap justify-content-between align-items-start">
      <div class="mt-1 mr-1 d-flex align-items-center">
        <label class="mr-1">Поиск:</label>
        <b-form-input
            placeholder="Поиск"
            type="text"
            class="d-inline-block"
            @input="findFilter"
        />
      </div>
      <div class="d-flex flex-wrap">
        <div class="mt-1 mr-1 d-flex align-items-center">
          <label class="mr-1">Бренд:</label>
          <b-form-select
              v-model="brandFilter"
              :options="listAllBrands"
          />
        </div>
        <div class="d-flex mt-1 align-items-center">
          <label class="mr-1">Категория:</label>
          <b-form-select
              v-model="objectFilter"
              :options="listAllCategories"
          />
        </div>
      </div>
    </div>
    <b-table
        id="table_products_check_defect"
        class="m-0"
        responsive
        :thead-tr-class="'text-nowrap'"
        :fields="fields"
        :items="selectedProductsId"
    >
      <template #head(name)="data">
        <span style="width: 200px">{{ data.label }}</span>
      </template>
      <template #head(amountOnShipments)="data">
        <div>{{ data.label }}</div>
      </template>
      <template #head(listPhoto)="data">
        <div>{{ data.label }}</div>

      </template>

      <template #cell(name)="data">
        <product-cell
            :imgData="{
            id: data.item.id,
            img: data.item.img[0],
            name: data.item.name,
            article: data.item.article,
            brand: data.item.brand,
          }"
        />
      </template>
      <template #cell(amountOnShipments)="data">
        <cell-total-amount-shipments
            :productID="data.item.id"
            :bold="true"
        />
      </template>
      <template #cell(listPhoto)="data">
        <div class="d-flex align-items-center">
          <div v-for="img in data.item.img"
               class="cursor-pointer  mr-1"
               :class="data.item.imgIdent === img ? 'selected' : ''"
               @click="data.item.imgIdent = img"
          >
            <img class="img-for-select" :src="img">
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import FInputDef from "@/components/UI/FInputDef";
import FInput from "@/components/UI/FInput";
import TableAmountProductsSize from '@/components/shipments/TableAmountProductsSize';
import ListCheckDefect from '@/components/shipments/ListCheckDefect';
import MenuSelectCheckDefect from '@/components/shipments/MenuSelectCheckDefect';
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BLink,
  BRow,
  BTabs,
  BTab,
  BSpinner,
  BTable
} from "bootstrap-vue";

import ProductCell from "@/components/ProductCell";
import CellTotalAmountShipments from "@/components/CellTotalAmountShipments";

export default {
  components: {
    ProductCell,
    FInputDef,
    FInput,
    BFormSelect,
    MenuSelectCheckDefect,
    BCol,
    BFormTextarea,
    BRow,
    ListCheckDefect,
    BTabs,
    BTab,
    BFormCheckbox,
    TableAmountProductsSize,
    CellTotalAmountShipments,
    BTable,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BLink,
    BFormInput,
  },
  name: "TableSelectPhotoIdent",
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Товары',
        },
        {
          key: 'amountOnShipments',
          label: 'количество',
        },
        {
          key: 'listPhoto',
          label: 'фото для приемки',
        },
      ],
      findFilter: '',
      brandFilter: '',
      objectFilter: '',
    }
  },
  methods: {

  },
  props: {
    productId: Number
  },
  watch: {},
  computed: {
    calcTotalPrice() {
      if (this.triggerSelect && this.triggerAddCheckDefect) {
        let totalSum = 0
        this.selectedProductsId.forEach(prod => {
          let sumCheck = prod.shipment.checkDefect.reduce((ac, check) => {
            return (+check.price || 0) + ac
          }, 0)
          console.log(sumCheck);
          totalSum += sumCheck * prod.totalAmount
        })
        return totalSum;
      }
    },
    ...mapState('products', ["selectedProductsId", 'listAllCategories', "listAllBrands", "triggerSelect", "triggerAddCheckDefect"]),
  },
}
</script>

<style scoped>

.total-price-checks {
  font-size: 18px;
  color: #4e5154;
}

.img-for-select {
  height: 60px;
}

.selected {
  position: relative;
  border: 2px solid #7367F0;
}

.selected:after {
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='13' height='13' rx='1' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.5 7.5C13.5 7.05 13.8 6.75 14.25 6.75C14.7 6.75 15 7.05 15 7.5V12.75C15 14.025 14.025 15 12.75 15H2.25C0.975 15 0 14.025 0 12.75V2.25C0 0.975 0.975 0 2.25 0H10.5C10.95 0 11.25 0.3 11.25 0.75C11.25 1.2 10.95 1.5 10.5 1.5H2.25C1.8 1.5 1.5 1.8 1.5 2.25V12.75C1.5 13.2 1.8 13.5 2.25 13.5H12.75C13.2 13.5 13.5 13.2 13.5 12.75V7.5ZM8.025 9.525L16.275 1.275C16.575 0.975 16.575 0.525 16.275 0.225C15.975 -0.075 15.525 -0.075 15.225 0.225L7.5 7.95L5.775 6.225C5.475 5.925 5.025 5.925 4.725 6.225C4.425 6.525 4.425 6.975 4.725 7.275L6.975 9.525C7.125 9.675 7.275 9.75 7.5 9.75C7.725 9.75 7.875 9.675 8.025 9.525Z' fill='%237367F0'/%3E%3C/svg%3E%0A");
  position: absolute;
  bottom: 0;
  left: 0;
  width: 16.5px;
  height: 15px;
}

.header-checks {
  font-size: 24px;
  text-align: center;
}

</style>