<template>
  <form-wizard
      @on-change="changeStep"
      class="steps-transparent"
      color="#7367F0"
      subtitle=""
      title=""
  >
    <tab-content
        title="Количество"
        icon="amount-for-shipment icon-step-shipment"
        :beforeChange="function() { return validData[current]}"
    >
      <table-amount-products
          @validData="function(val) {validData[current] = val}"
      />
    </tab-content>
    <tab-content
        :beforeChange="function(val) { return validData[current]}"
        title="Проверка на брак"
        icon="check-defect-icon icon-step-shipment"
    >
      <table-check-defect
          :ref="'checkDefect'"
      />
    </tab-content>
    <tab-content
        :beforeChange="function(val) { return validData[current]}"
        title="Фото для приемки"
        icon="photo-shipment-icon icon-step-shipment"
    >
      <table-select-photo-ident/>
    </tab-content>
    <tab-content
        :beforeChange="function() { return !!validData[current]}"
        title="Проверка"
        icon="check-shipment-icon icon-step-shipment"
    >
      <shipment-requisites
          @validData="function(val) {validData[current] = val;  finalValid = !!val}"
      />
    </tab-content>
    <template slot="footer" slot-scope="props">
      <div class="py-1 d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center w-50">
          <b-button variant="primary"
                    v-if="props.activeTabIndex > 0"
                    @click="props.prevTab()"
                    :style="props.fillButtonStyle"
          >
            Назад
          </b-button>
        </div>
        <div class="d-flex justify-content-end align-items-center w-50">
          <b-button :variant="!validData[current] ? 'secondary': 'primary'"
                    v-if="!props.isLastStep"
                    id="create_shipment_button_next"
                    @click="props.nextTab()" class="wizard-footer-right"
                    :style="props.fillButtonStyle"
          >
            Далее
          </b-button>
          <b-button variant="primary"
                    :variant="!finalValid ? 'secondary': 'primary'"
                    v-else
                    @click="function() { props.nextTab(); if(validData[current]) arrangeShipment()}"
                    :style="props.fillButtonStyle"
          >
            Оформить
          </b-button>
        </div>
      </div>

    </template>
    <b-tooltip
        v-if="!validData[current]"
        target="create_shipment_button_next"
        placement="top"
        triggers="hover"
        :title="prompts[current]"
    >
      {{ prompts[current] }}
    </b-tooltip>
  </form-wizard>
</template>

<script>
import {
  BButton,
  BCard,
  BTooltip,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import TableSelectPhotoIdent from '@/components/shipments/TableSelectPhotoIdent'

import '@/@core/assets/fonts/feather/iconfont.css'
import {FormWizard, TabContent, WizardButton} from 'vue-form-wizard'
import '@/@core/scss/vue/libs/vue-wizard.scss'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import TableAmountProducts from "@/components/shipments/TableAmountProducts";
import TableCheckDefect from "@/components/shipments/TableCheckDefect";
import ShipmentRequisites from "@/components/shipments/ShipmentRequisites";
import {mapActions} from 'vuex'


export default {
  name: "CreatingShipment",
  data() {
    return {
      finalValid: false,
      steps: [
        "amountProducts",
        "checkDefect",
        "initPhoto",
        "requisites",
      ],
      validData: {
        amountProducts: false,
        checkDefect: true,
        initPhoto: true,
        requisites: false,
      },
      prompts: {
        amountProducts: 'Необходимо указать количество для всех позиций',
        checkDefect: '',
        initPhoto: '',
        requisites: '',
      },
      current: 'amountProducts'
    }
  },

  components: {
    FormWizard,
    ShipmentRequisites,
    TableSelectPhotoIdent,
    TableAmountProducts,
    TabContent,
    TableCheckDefect,
    WizardButton,
    BCard,
    BButton,
    BCol,
    BModal,
    BTooltip,
    BRow,
    BFormInput,
    BFormGroup,
    BFormSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {},
  methods: {
    ... mapActions('shipments', ['sendCurrentShipment']),
    async arrangeShipment() {
      if (this.finalValid) await this.sendCurrentShipment(this.validData.requisites)
      //this.$router.push('/shipments');
    },
    changeStep(prev, current) {
      this.current = this.steps[current];
      if (current !== 1 && this.$refs?.checkDefect) this.$refs.checkDefect.selectAllForCheckDefect(false);
    },
    ...mapActions('products', ["hideProductPanel"]),
  },

  beforeDestroy() {
    console.log('beforeDestroy')
  }
}
</script>

<style scoped>

</style>