<template>
  <div style="max-width: 370px">
    <b-badge variant="primary" class="d-inline-block badges-checks" v-for="(check, i) in getListChecks"
             :key="i"
    >
      {{ check.label }}
    </b-badge>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem, BBadge} from 'bootstrap-vue'
import {mapState} from "vuex";
export default {
  name: "ListCheckDefect",
  components: { BListGroup, BListGroupItem, BBadge},
  computed: {
    ... mapState('products', ['triggerAddCheckDefect']),
    getListChecks() {
      if (this.triggerAddCheckDefect) return this.listChecks;
      return [];
    }
  },
  props: {
    listChecks: Array
  },
}
</script>

<style scoped>
.badges-checks {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>