<template>
  <div>
    <div class="px-2 pt-2">
      <h4>
        Укажите количество
      </h4>
      <div style="min-width: 320px">
        По каждой позиции укажите количество. Если товар имеет размеры, необходимо указать количество хотя бы для одного размера.
      </div>
    </div>
    <div class="p-2 d-flex flex-wrap justify-content-between align-items-start">
      <div class="mt-1 mr-1 d-flex align-items-center">
        <label class="mr-1">Поиск:</label>
        <b-form-input
            placeholder="Поиск"
            type="text"
            class="d-inline-block"
            @input="findFilter"
        />
      </div>
      <div class="d-flex flex-wrap">
        <div class="mt-1 mr-1 d-flex align-items-center">
          <label class="mr-1">Бренд:</label>
          <b-form-select
              v-model="brandFilter"
              :options="listAllBrands"
          />
        </div>
        <div class="d-flex mt-1 align-items-center">
          <label class="mr-1">Категория:</label>
          <b-form-select
              v-model="objectFilter"
              :options="listAllCategories"
          />
        </div>
      </div>
    </div>
    <b-table
        id="table_products_to_shipment"
        class="m-0"
        responsive
        :thead-tr-class="'text-nowrap'"
        :fields="fields"
        :details-td-class="'p-0'"
        :items="selectedProductsId"
    >
      <template #head(name)="data">
        <span style="width: 200px">{{ data.label }}</span>
      </template>
      <template #head(amountOnShipments)="data">
        <div style="width: 50px">{{ data.label }}</div>
      </template>
      <template #head(stockff)="data">

      </template>
      <template #head(stockmp)="data">
        <span style="width: 50px; padding-right: 10px">{{ data.label }}</span>
      </template>
      <template #cell(name)="data">
        <product-cell
            @openDetail="data.toggleDetails()"
            :_showDetails="data.item._showDetails"
            :detailsShowing="Array.isArray(data.item.stockff) || Array.isArray(data.item.stockmp)"
            :imgData="{
            id: data.item.id,
            img: data.item.img[0],
            name: data.item.name,
            article: data.item.article,
            brand: data.item.brand,
          }"
        />
      </template>
      <template #cell(amountOnShipments)="data">
        <b-form-input
            style="width: 100px"
            :value="data.item.totalAmount"
            placeholder="0"
            @input="function(val) {setTotalAmount({val, productId: data.item.id})}"
            v-if="data.item.sizes[0].value === false"
        />
        <cell-total-amount-shipments
            @openDetail="data.toggleDetails()"
            v-else
            :productID="data.item.id"
        />
      </template>
      <template #cell(stockff)="data">
        <div style="width: 50px">{{
            Array.isArray(data.item.stockff) ? data.item.stockff.reduce((a, c) => {
              return a + c.stock.storage
            }, 0) : data.item.stockff || 0
          }}
        </div>
      </template>
      <template #cell(stockmp)="data">
        <div style="width: 50px">{{
            Array.isArray(data.item.stockmp) ? data.item.stockmp.reduce((a, c) => {
              return a + c.stock
            }, 0) : data.item.stockmp || 0
          }}
        </div>
      </template>
      <template #row-details="data">
        <table-amount-products-size
            :productId="data.item.id"
            @calcTotalAmount="setTotalAmount"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import FInputDef from "@/components/UI/FInputDef";
import FInput from "@/components/UI/FInput";
import TableAmountProductsSize from '@/components/shipments/TableAmountProductsSize'
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormSelect,
  BLink,
  BRow,
  BSpinner,
  BTable
} from "bootstrap-vue";

import ProductCell from "@/components/ProductCell";
import CellTotalAmountShipments from "@/components/CellTotalAmountShipments";

export default {
  components: {
    ProductCell,
    FInputDef,
    FInput,
    BFormSelect,
    BCol,
    BRow,
    BFormCheckbox,
    TableAmountProductsSize,
    CellTotalAmountShipments,
    BTable,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BLink,
    BFormInput,
  },
  name: "TableAmountProducts",
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Товары',
        },
        {
          key: 'amountOnShipments',
          label: 'количество',
        },
        {
          key: 'stockff',
          label: 'На ФФ ',
          sortable: true,
        },
        {
          key: 'stockmp',
          label: 'на МП',
          sortable: true,
        },
      ],
      findFilter: '',
      brandFilter: '',
      objectFilter: '',
    }
  },
  methods: {
    ...mapActions('products', ["setTotalAmount"])
  },
  props: {
    productId: Number,
  },
  computed: {
    getAllTotalAmount() {
      if (this.triggerSelect > 0) {
        let res = this.selectedProductsId.every(prod => {
          return +prod.totalAmount > 0
        });
        console.log(res);
        return res;
      }
    },
    ...mapState('products', ["productsWidthWB", "selectedProductsId", 'listAllCategories', "listAllBrands", "triggerSelect"]),
  },
  watch: {
    getAllTotalAmount(val) {
      console.log('watch')
      this.$emit('validData', val)
    }
  }
}
</script>

<style scoped>

</style>