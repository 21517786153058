<template>
  <div class="shipment-requisites_container">
    <div class="shipment-requisites_header mb-1">
      <h3>
        Информация по отгрузки
      </h3>
      <div style="min-width: 320px">
        Выберите кто привезет ваш товар на наш склад и заполните данные по отгрузке
      </div>
    </div>
    <div class="button-container mb-3">
      <div class="mr-1 button-type-shipment"
           :class="currentShipment.customerWillDeliver ? 'button-type-shipment_select' : 'button-type-shipment_unselect'"
           @click="currentShipment.customerWillDeliver = true"
      >
        <div>Привезу сам</div>
        <span>Сам погружу и отправлю товар до вашего склада</span>
      </div>
      <div class="ml-1 button-type-shipment"
           :class="currentShipment.customerWillDeliver === false ? 'button-type-shipment_select' : 'button-type-shipment_unselect'"
           @click="currentShipment.customerWillDeliver = false"
      >
        <div>Забрать</div>
        <span>Мы приедем за вашим товаром и доставим до нашего склада</span>
      </div>
    </div>
    <div v-if="currentShipment.customerWillDeliver === true" class="mb-3 date-shipment_at_client">
      <h5>
        Дата доставки*
      </h5>
      <b-form-radio class="ml-1 mb-1"
                    v-model="currentShipment.isExactDate"
                    name="select-type-shipment"
                    :value="true"
      >
        Точный дата доставки*
      </b-form-radio>
      <b-form-radio class="ml-1 mb-1"
                    v-model="currentShipment.isExactDate"
                    :value="false"
                    name="select-type-shipment"

      >
        Приблизительной дата доставки*
      </b-form-radio>
      <b-form-datepicker
          id="example-datepicker"
          v-model="currentShipment.date"
          class="mb-1"
      />
    </div>
    <div v-else-if="currentShipment.customerWillDeliver === false" class="mb-3 date-shipment_at_client">
      <div class="d-flex flex-wrap align-items-end justify-content-between mb-1">
        <div class="half mr-1">
          <h5>
            Дата отгрузки*
          </h5>
          <b-form-datepicker
              id="example-datepicker"
              v-model="currentShipment.date"
          />
        </div>
        <div class="half ml-2">
          <h5>
            Страна отгрузки*
          </h5>
          <b-form-select
              v-model="currentShipment.country"
              :options="listCountry"
          />
        </div>
      </div>
      <h5>
        Адрес отгрузки*
      </h5>
      <b-form-input class="w-100 mb-1"
                    v-model="currentShipment.address"
                    list="list-address"/>
      <datalist id="list-address">
        <option v-for="address in listAddress">{{ address }}</option>
      </datalist>
      <b-form-checkbox
          v-model="currentShipment.saveAddress"
          name="saveAddress"
          switch
          inline
      >
        Сохранить адрес
      </b-form-checkbox>
    </div>
    <div v-if="currentShipment.customerWillDeliver !== null"
         class="mb-3 volume-container flex-wrap d-flex align-items-end justify-content-between">
      <div class="mr-1">
        <h5>Объем груза(м/куб)*:</h5>
        <div v-if="currentShipment.customerWillDeliver === true">
          <b-form-radio class="ml-1 mb-1"
                        v-model="currentShipment.isExactVolume"
                        name="select-volume-state"
                        :value="true"
          >
            Точный объем груза
          </b-form-radio>
          <b-form-radio class="ml-1 mb-1"
                        v-model="currentShipment.isExactVolume"
                        name="select-volume-state"
                        :value="false"
          >
            Приблизительной объем груза
          </b-form-radio>
        </div>
        <b-form-input
            v-model="currentShipment.totalVolume"
        />
      </div>
      <div class="mr-1">
        <h5>Количество паллет(шт)*:</h5>
        <b-form-input
            v-model="currentShipment.amountPallet"
        />
      </div>
      <div class="mr-1">
        <h5>Количество коробов(шт)*:</h5>
        <b-form-input
            v-model="currentShipment.amountBox"
        />
      </div>
    </div>
    <div v-if="currentShipment.customerWillDeliver !== null" class="comment-container mb-1">
      <h5>Ваш комментарий:</h5>
      <b-form-textarea
          v-model="currentShipment.comment"
      />
    </div>
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormDatepicker,
  BFormRadio,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import {mapActions, mapState} from "vuex";

export default {
  name: "ShipmentRequisites",
  components: {
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormDatepicker,
    BFormRadio,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormTextarea,
  },
  computed: {

    getValid() {
      return !!this.currentShipment.date &&
          !!this.currentShipment.amountBox &&
          !!this.currentShipment.amountPallet &&
          !!this.currentShipment.totalVolume &&
          this.currentShipment.customerWillDeliver !== null &&
          (typeof this.currentShipment.isExactDate === "boolean" || this.currentShipment.customerWillDeliver === false) &&
          (typeof this.currentShipment.isExactVolume === "boolean" || this.currentShipment.customerWillDeliver === false) &&
          (!!this.currentShipment.address || this.currentShipment.customerWillDeliver === false) &&
          (!!this.currentShipment.country || this.currentShipment.customerWillDeliver === false)
    }
  },
  data() {
    return {
      currentShipment: {
        customerWillDeliver: null,
        isExactDate: '',
        isExactVolume: '',
        date: '',
        country: '',
        saveAddress: false,
        address: '',
        amountBox: '',
        amountPallet: '',
        totalVolume: '',
        comment: '',
      },
      listCountry: [
        {value: 'Россия', text: 'Россия'},
        {value: 'Китай', text: 'Китай'},
        {value: 'Белоруссия', text: 'Белоруссия'},
        {value: 'Казахстан', text: 'Казахстан'},
      ],
      listAddress: [
        'Иркутск, Мира 4-1',
        'Красноярск, Мира 1-2',
        'Москва, ул. Никольская, д. 27/2 ул. Россошанская, д. 17а',
      ],
    }
  },
  watch: {
    /*    currentShipment: {
          handler: function (newVal) {
            if (
                !newVal.date &&
                !newVal.amountBox &&
                !newVal.amountPallet &&
                !newVal.totalVolume &&
                newVal.customerWillDeliver !== null &&

                newVal.isExactDate  !== null &&
                newVal.isExactVolume  !== null &&
                newVal.country  !== null &&
                newVal.saveAddress  !== null &&
                newVal.comment  !== null
            ) this.validData(true);
            else this.validData(false);
          },
          deep: true
        }*/
    getValid(valid) {
      if (valid) {
        this.validData(this.currentShipment);
      } else this.validData(false);
    }
  },
  props: {},
  methods: {
    validData(currentShipment) {
      this.$emit('validData', currentShipment)
    }
  },
  unmounted() {

  }
}
</script>

<style scoped>

.button-container {
  display: flex;
  width: 100%;
}

.button-type-shipment {
  width: calc(50% - 1rem);
  border: 1px solid;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}

.button-type-shipment > div {
  font-size: 20px;
  font-weight: 500;
}

.button-type-shipment > span {
  font-size: 14px;
}

.button-type-shipment_select {
  color: #7367F0;
  border-color: #7367F0;
  background-color: #F7F6FE;
}

.button-type-shipment_unselect {
  color: #82868B;
  border-color: #82868B;
  background-color: #F8F8F8;

}

.shipment-requisites_container {
  padding: 20px;
}

.half {
  width: calc(50% - 1.5rem);
}

</style>