<template>
  <div class="container-check-defect">
    <h5 class="header-checks">Список проверок на брак</h5>
    <div class="w-100 my-1 d-flex justify-content-between align-items-center">
      <label class="mr-1" for="find_check_defect">Поиск:</label>
      <b-form-input
          v-model="filter"
          placeholder="Название или описание проверки"
          id="find_check_defect"
      />
    </div>
    <div style="max-height: 500px" class="overflow-auto">
      <div class="item-check" v-for="(item, i) in getListChecksDefects"
           :key="i"
      >
        <div class="w-100 d-flex align-items-center justify-content-between item-check-defect">
          <b-form-checkbox
              :checked="selectedInSome(item)"
              @change="function(val) { addDellCheckDefect(val, item) }"
          >
            <span class="font-weight-bold"> {{ item.label }} </span>
          </b-form-checkbox>
          <div class="text-nowrap mx-1 font-weight-bold price-check-defect">
            <span v-if="typeof item.price === 'number'">{{ item.price }}руб/шт</span>
            <span v-else>
            {{ item.price }}
            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.46067 1H3.54067C3.24067 1 3.09067 1.227 3.11267 1.42C3.22667 2.413 3.44067 3.553 3.79867 4.282C4.27567 5.255 5.42667 6.348 6.29567 7.071C6.35249 7.12052 6.4253 7.1478 6.50067 7.1478C6.57604 7.1478 6.64886 7.12052 6.70567 7.071C7.57567 6.348 8.72567 5.255 9.20267 4.281C9.56067 3.553 9.77467 2.413 9.88967 1.42C9.91167 1.227 9.76067 1 9.46167 1H9.46067ZM3.54067 0C2.69667 0 2.02167 0.696 2.11867 1.534C2.23467 2.539 2.46167 3.828 2.90067 4.722C3.48167 5.906 4.78867 7.118 5.65667 7.84C6.15067 8.25 6.85067 8.25 7.34467 7.84C8.21267 7.118 9.51967 5.906 10.1007 4.722C10.5397 3.828 10.7667 2.539 10.8827 1.534C10.9797 0.696 10.3047 0 9.46067 0H3.53867H3.54067Z" fill="#7367F0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.54 15H9.46C9.76 15 9.91 14.773 9.888 14.58C9.774 13.587 9.56 12.447 9.202 11.719C8.725 10.745 7.574 9.652 6.705 8.929C6.64819 8.87948 6.57537 8.8522 6.5 8.8522C6.42463 8.8522 6.35181 8.87948 6.295 8.929C5.425 9.652 4.275 10.745 3.798 11.719C3.44 12.447 3.226 13.587 3.111 14.581C3.089 14.773 3.241 15 3.54 15ZM9.46 16C10.304 16 10.979 15.304 10.882 14.466C10.766 13.46 10.539 12.172 10.1 11.278C9.519 10.094 8.212 8.882 7.344 8.16C7.1077 7.9612 6.80881 7.85219 6.5 7.85219C6.19119 7.85219 5.8923 7.9612 5.656 8.16C4.788 8.882 3.481 10.094 2.9 11.278C2.461 12.172 2.234 13.46 2.118 14.466C2.021 15.304 2.696 16 3.54 16H9.461H9.46ZM0.5 0.5C0.5 0.367392 0.552679 0.240215 0.646447 0.146447C0.740215 0.0526785 0.867392 0 1 0H12C12.1326 0 12.2598 0.0526785 12.3536 0.146447C12.4473 0.240215 12.5 0.367392 12.5 0.5C12.5 0.632608 12.4473 0.759785 12.3536 0.853553C12.2598 0.947321 12.1326 1 12 1H1C0.867392 1 0.740215 0.947321 0.646447 0.853553C0.552679 0.759785 0.5 0.632608 0.5 0.5Z" fill="#7367F0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 15.5C0.5 15.3674 0.552679 15.2402 0.646447 15.1464C0.740215 15.0527 0.867392 15 1 15H12C12.1326 15 12.2598 15.0527 12.3536 15.1464C12.4473 15.2402 12.5 15.3674 12.5 15.5C12.5 15.6326 12.4473 15.7598 12.3536 15.8536C12.2598 15.9473 12.1326 16 12 16H1C0.867392 16 0.740215 15.9473 0.646447 15.8536C0.552679 15.7598 0.5 15.6326 0.5 15.5Z" fill="#7367F0"/>
</svg>
          </span>
          </div>
        </div>
        <div class="py-1">
          <div>
            {{ item.description }}
          </div>
          <app-collapse
              v-if="item.descriptionDetails"
              type="default"
          >
            <app-collapse-item title="подробнее">
              {{ item.descriptionDetails }}
            </app-collapse-item>
          </app-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BFormInput, BListGroup, BListGroupItem, BFormCheckbox} from 'bootstrap-vue'
import {mapActions, mapState} from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: "MenuSelectCheckDefect",
  data() {
    return {
      filter: '',
    }
  },
  components: {
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormInput,
    AppCollapse,
    AppCollapseItem
  },
  computed: {
    getListChecksDefects() {
      return this.listChecksDefects.filter(check => check.label?.includes(this.filter) || check.description?.includes(this.filter) || check.descriptionDetails?.includes(this.filter))
    },
    ...mapState('services', ["listChecksDefects"]),
  },
  props: {
    productsForCheck: Array
  },
  methods: {
    selectedInSome(check) {
      return this.productsForCheck.some(prod => prod.shipment.checkDefect.some(ch => ch.id === check.id))
    },
    addDellCheckDefect(val, item) {
      this.productsForCheck.forEach(checksProd => {
        if (val) {
          if (checksProd.shipment.checkDefect.every(check => check.id !== item.id)) checksProd.shipment.checkDefect.push(item);
          this.setTriggerAddCheckDefect()
        } else {
          checksProd.shipment.checkDefect.splice(checksProd.shipment.checkDefect.findIndex(check => check.id === item.id), 1)
          this.setTriggerAddCheckDefect()
        }
      })
    },
    ...mapActions('products', ['setTriggerAddCheckDefect']),
  },
  mounted() {

  }
}
</script>

<style scoped>
.item-check {
  border-bottom: 1px solid #C7CCCF;
  margin-bottom: 1rem;
}

.header-checks {
  font-size: 24px;
  text-align: center;
}
</style>