<template>
  <div class="table-check-container">
    <div class="total-price-checks pt-1 px-1 font-weight-bold d-flex flex-wrap align-items-center justify-content-between">
      <h4 >Проверка на брак</h4>
      <div style="font-size:14px">
        <span>Сумма услуг:</span>
        <span style="height: 24px; margin-left: 9px;"> {{ calcTotalPrice }} руб</span>
      </div>
    </div>
    <div class="px-1"> Сперва выберите товары которые нужно проверить на брак на этапе приемки, затем из списка выберите услуги проверок</div>
    <div class="p-2 d-flex flex-wrap justify-content-between align-items-start">
      <div class="mt-1 mr-1 d-flex align-items-center">
        <label class="mr-1">Поиск:</label>
        <b-form-input
            placeholder="Поиск"
            type="text"
            class="d-inline-block"
            @input="findFilter"
        />
      </div>
      <div class="d-flex flex-wrap">
        <div class="mt-1 mr-1 d-flex align-items-center">
          <label class="mr-1">Бренд:</label>
          <b-form-select
              v-model="brandFilter"
              :options="listAllBrands"
          />
        </div>
        <div class="d-flex mt-1 align-items-center">
          <label class="mr-1">Категория:</label>
          <b-form-select
              v-model="objectFilter"
              :options="listAllCategories"
          />
        </div>
      </div>
    </div>
    <b-table
        id="table_products_check_defect"
        class="m-0"
        responsive
        :thead-tr-class="'text-nowrap'"
        :fields="fields"
        :items="selectedProductsId"
    >
      <template #head(name)="data">
        <span style="width: 200px">{{ data.label }}</span>
      </template>
      <template #head(amountOnShipments)="data">
        <div>{{ data.label }}</div>
      </template>
      <template #head(checkDefect)="data">
        <div>{{ data.label }}</div>
      </template>
      <template #head(select)="data">
        <b-form-checkbox
            @change="selectAllForCheckDefect"
            :checked="!!productsForCheck.length"
        />
      </template>
      <template #cell(name)="data">
        <product-cell
            :imgData="{
            id: data.item.id,
            img: data.item.img[0],
            name: data.item.name,
            article: data.item.article,
            brand: data.item.brand,
          }"
        />
      </template>
      <template #cell(amountOnShipments)="data">
        <cell-total-amount-shipments
            :productID="data.item.id"
            :bold="true"
        />
      </template>
      <template #cell(checkDefect)="data">
        <list-check-defect
            :listChecks="data.item.shipment.checkDefect"
        />
      </template>
      <template #cell(select)="data">
        <b-form-checkbox
            @change="function(val) { selectForCheckDefect(val, data.item) }"
            :checked="productsForCheck.some(prod=>prod.id === data.item.id)"
        />
      </template>
    </b-table>
    <b-modal
        :hide-header="true"
        :hide-footer="true"
        :visible="showModalCheckDefect"
        @hidden="selectAllForCheckDefect(false)"
        @change="switchModalCheckDefect"
    >
      <b-tabs content-class="mt-3"
              :nav-class="'d-none'"
              :content-class="'m-0'"
              v-model="tabIndex"
      >
        <b-tab>
          <menu-select-check-defect
              :ref="'mscd'"
              :productsForCheck="productsForCheck"
          />
          <div class="d-flex align-items-center justify-content-between w-100">
            <b-button
                @click="switchModalCheckDefect"
                variant="primary"
            >
              Добавить
            </b-button>

            <b-button
                variant="outline-primary"
                @click="tabIndex=1"
            >
              + Создать свою проверку
            </b-button>
          </div>
        </b-tab>
        <b-tab>
          <h5 class="header-checks">Создание проверки на брак</h5>
          <b-form-input class="mb-1 w-100"
                        v-model="newCheckName"
                        placeholder="Краткое название проверки на брак"
          >

          </b-form-input>
          <b-form-textarea class="mb-1 w-100"
                           v-model="newCheckDescription"
                           placeholder="Опишите подробно, что нам нужно проверить"
          >
          </b-form-textarea>

          <div class="d-flex align-items-center justify-content-between w-100">
            <b-button
                variant="outline-primary"
                @click="tabIndex=0"
            >
              Назад
            </b-button>
            <b-button
                @click="addNewCheckDefect"
                variant="primary"
            >
              Добавить
            </b-button>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import FInputDef from "@/components/UI/FInputDef";
import FInput from "@/components/UI/FInput";
import TableAmountProductsSize from '@/components/shipments/TableAmountProductsSize';
import ListCheckDefect from '@/components/shipments/ListCheckDefect';
import MenuSelectCheckDefect from '@/components/shipments/MenuSelectCheckDefect';
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BLink,
  BRow,
  BTabs,
  BTab,
  BSpinner,
  BTable
} from "bootstrap-vue";

import ProductCell from "@/components/ProductCell";
import CellTotalAmountShipments from "@/components/CellTotalAmountShipments";

export default {
  components: {
    ProductCell,
    FInputDef,
    FInput,
    BFormSelect,
    MenuSelectCheckDefect,
    BCol,
    BFormTextarea,
    BRow,
    ListCheckDefect,
    BTabs,
    BTab,
    BFormCheckbox,
    TableAmountProductsSize,
    CellTotalAmountShipments,
    BTable,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BLink,
    BFormInput,
  },
  name: "TableCheckDefect",
  data() {
    return {
      newCheckName: '',
      newCheckDescription: '',
      tabIndex: 0,
      fields: [
        {
          key: 'name',
          label: 'Товары',
        },
        {
          key: 'amountOnShipments',
          label: 'количество',
        },
        {
          key: 'checkDefect',
          label: 'задания',
        },
        {
          key: 'select',
          label: 'На ФФ ',
        },
      ],
      selectedChecksDefect: [],
      productsForCheck: [],
      findFilter: '',
      brandFilter: '',
      objectFilter: '',
    }
  },
  methods: {
    async addNewCheckDefect() {
      let newCheck = await this.addCustomCheck({name: this.newCheckName, description: this.newCheckDescription});
      this.$refs.mscd.addDellCheckDefect(true, newCheck);
      this.newCheckName = '';
      this.newCheckDescription = '';
      this.tabIndex = 0
      this.switchModalCheckDefect();
    },
    ...mapActions('services', ['addCustomCheck']),
    ...mapActions('products', ["showProductPanel", "hideProductPanel", "setCountCheckDefect", "switchModalCheckDefect"]),
    selectForCheckDefect(val, prod) {
      if (val) {
        this.productsForCheck.push(prod);
        this.showProductPanel();
      } else {
        let i = this.productsForCheck.findIndex(p => p.id === prod.id);
        this.productsForCheck.splice(i, 1);
      }
    },
    selectAllForCheckDefect(val) {
      if (val) {
        this.productsForCheck = [].concat(this.selectedProductsId)
        this.showProductPanel();
      } else {
        this.productsForCheck = [];
      }
    },
    addListChecksToShipment() {
      this.productsForCheck.forEach(prod => prod.shipment.checkDefect = this.selectedChecksDefect.slice());
    },
    delCheck(index) {
      this.productsForCheck.forEach(prod => prod.shipment.checkDefect?.splice(index, 1));
    },
    ...mapActions('products', ["setTotalAmount"])
  },
  props: {
    productId: Number
  },
  watch: {
    productsForCheck: {
      handler() {
        this.setCountCheckDefect(this.productsForCheck.length)
        if (!this.productsForCheck.length) {
          this.hideProductPanel();
        }
      },
      deep: true
    }
  },
  computed: {
    calcTotalPrice() {
      if (this.triggerSelect && this.triggerAddCheckDefect) {
        let totalSum = 0
        this.selectedProductsId.forEach(prod => {
          let sumCheck = prod.shipment.checkDefect.reduce((ac, check) => {
            return (+check.price || 0) + ac
          }, 0)
          console.log(sumCheck);
          totalSum += sumCheck * prod.totalAmount
        })
        return totalSum;
      }
    },
    ...mapState('products', ["productsWidthWB", "selectedProductsId", 'listAllCategories', "listAllBrands", "showModalCheckDefect", "triggerSelect", "triggerAddCheckDefect"]),
  },
  beforeDestroy() {
    console.log('beforeDestroy');
  }
}
</script>

<style scoped>

.total-price-checks {
  font-size: 18px;
  color: #4e5154;
}

.header-checks {
  font-size: 24px;
  text-align: center;
}

</style>